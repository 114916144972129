<template>
  <div>
    <div v-if="isInterviewer" class="d-flex flex-column">
      <div class="title">
        {{ $t("what_you_evaluate") }}
      </div>
      <div
        class="text--secondary caption mb-1"
        v-html="$t('for_each_skill')"
      ></div>
      <div style="width: 30%">
        <v-autocomplete
          v-model="historic"
          dense
          clearable
          :label="$t('favorites')"
          return-object
          :items="lists"
          item-text="name"
        ></v-autocomplete>
      </div>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="addClassification()"
      >
        <v-row>
          <v-col cols="12" md="11" class="pr-0 mr-0">
            <div class="d-flex flex-row justify-sapce-between">
              <div style="width: 98.5%">
                <v-text-field
                  :label="$t('inserts_points_evaluate')"
                  outlined
                  dense
                  v-model="classification.designation"
                ></v-text-field>
              </div>
            </div>

            <template
              v-for="(evaluationPoint,
              index) in classification.evaluationPoints"
            >
              <div class="d-flex align-center mb-2" :key="`${index}gsgs`">
                <div class="mx-2" style="width: 2%">
                  <v-icon small>mdi-arrow-right-bottom</v-icon>
                </div>
                <div class="pr-2" style="width: 76%">
                  <v-text-field
                    :label="$t('what_aspects')"
                    outlined
                    dense
                    hide-details
                    v-model="evaluationPoint.name"
                  ></v-text-field>
                </div>
                <div style="width: 19%">
                  <v-text-field
                    :label="$t('weight')"
                    outlined
                    dense
                    :rules="requiredRules"
                    hide-details
                    v-model.number="evaluationPoint.percentageWeight"
                  ></v-text-field>
                </div>
                <div class="ml-2" style="width: 5%">
                  <v-icon
                    v-if="classification.evaluationPoints.length > 1"
                    @click="removeEvaluationPoint(index)"
                    >mdi-delete</v-icon
                  >
                </div>
              </div>
            </template>
            <div
              class="primary--text ml-10 mb-5"
              style="cursor: pointer"
              @click="addEvaluationPoints()"
            >
              {{ $t("add") }}
            </div>
          </v-col>
          <v-col cols="12" md="1" class="pl-0 ml-0">
            <v-btn fab dark outlined small color="primary" type="submit">
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>

    <div class="d-flex flex-column justify-space-between">
      <template
        v-for="(classification, index) in selectedRecruter.classification"
      >
        <v-row :key="index">
          <v-col cols="12" md="10">
            <div class="d-flex flex-row justify-space-between">
              <div style="width: 80%">
                {{ index + 1 }}. {{ classification.designation }}
                <!-- <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" small v-bind="attrs" v-on="on"
                      >mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ classification.purpose }}</span>
                </v-tooltip> -->
              </div>
              <!-- <div class="primary--text text-right" style="width: 20%">
                {{ classification }}%
              </div> -->
            </div>
            <div class="ml-5 my-2">
              <template
                v-for="(evaluationPoint,
                pointIndex) in classification.evaluationPoints"
              >
                <div
                  class="d-flex flex-row justify-space-between"
                  :key="`${evaluationPoint.name}`"
                >
                  <div class="text--secondary" style="width: 80%">
                    {{ index + 1 }}.{{ pointIndex + 1 }}.
                    {{ evaluationPoint.name }}
                    <span class="primary--text"
                      >{{ evaluationPoint.percentageWeight }}%</span
                    >
                  </div>
                </div>
                <div class="mt-5" :key="`${evaluationPoint.name}`">
                  <v-slider
                    v-model="evaluationPoint.scoring"
                    :label="$t('level_of_satisfaction')"
                    step="1"
                    thumb-label="always"
                    :thumb-color="thumbColor(evaluationPoint.scoring)"
                    :thumb-size="22"
                    @change="
                      updateRecruterClassification(classification, pointIndex)
                    "
                    :readonly="readOnlyClassification"
                  ></v-slider>
                </div>
              </template>
            </div>
            <!-- <div class="mt-5">
              <v-slider
                v-model="classification.points"
                :label="$t('level_of_satisfaction')"
                step="1"
                thumb-label="always"
                :thumb-color="thumbColor(classification.points)"
                :thumb-size="22"
                @change="updateRecruterClassification(classification)"
                :readonly="readOnlyClassification"
              ></v-slider>
            </div> -->
          </v-col>
          <v-col v-if="!isCompleted" cols="12" md="2">
            <div v-if="classification.createdBy === userId">
              <v-btn
                icon
                x-small
                @click="setClassificationToEdit(classification)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                x-small
                @click="setClassificationToDelete(classification)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-col>
          <div>
            <!-- <pre>{{ getSpecificRatingInQuestion(classification.id) }}</pre> -->

            <v-row class="d-flex flex-column pl-4 mb-4">
              <div class="text-capitalize">{{ $t("evaluator") }}</div>
              <div class="d-flex flex-row">
                <div class="">
                  <span class="my-2" :class="{ 'mx-3': index % 2 === 0 }">
                    <v-chip label color="transparent">
                      <v-avatar>
                        <v-img
                          :src="
                            rantingMemberQuestion.user.photo
                              ? `${apiUrl}/images/user/${rantingMemberQuestion.user.photo}`
                              : `/avatar.png`
                          "
                        ></v-img>
                      </v-avatar>
                      <div class="recruter-name ml-1">
                        <div class="width: 100%" style=" display: inline-flex">
                          {{ recruterName(rantingMemberQuestion) }}:
                          <!-- </div>
                    <div class="whirelab-results-rating-value"> -->
                          <v-progress-linear
                            :value="
                              getSpecificRatingInQuestion(classification.id)
                            "
                            readonly
                            height="16"
                            rounded
                            :color="
                              thumbColor(
                                getSpecificRatingInQuestion(classification.id)
                              )
                            "
                            class="mt-0 ml-1"
                            style="width: 100px;"
                          >
                            <span class="black--text"
                              >{{
                                getSpecificRatingInQuestion(classification.id)
                              }}%</span
                            >
                          </v-progress-linear>
                        </div>
                      </div>
                    </v-chip>
                    <!-- <pre> {{ ratingMember(recruter) }}</pre> -->
                  </span>
                </div>
              </div>
              <v-btn
                text
                class="text-capitalize"
                @click="
                  noteDialog = true;
                  idclass = classification.id;
                "
                ><v-icon color="primary"
                  >mdi-comment-text-multiple-outline</v-icon
                >
                {{ $t("obs") }}({{ classification.notes.length }})</v-btn
              >
            </v-row>
          </div>
        </v-row>
      </template>
      <div>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="primary"
              @click="openList()"
              ><v-icon>mdi-playlist-plus</v-icon></v-btn
            >
          </template>
          <span>{{ $t("save_script") }}</span>
        </v-tooltip>
      </div>
      <!-- >{{ selectedStage }} -->
      <div>
        <v-row class="d-flex flex-column pl-4 mt-5">
          <div class="">{{ $t("overall_rating_of_the_evaluators") }}</div>
          <div class="d-flex flex-row">
            <div class="">
              <span
                v-for="(recruter, index) in recruters"
                :key="recruter.user.id"
                :content="recruter.comments.length"
                :value="recruter.comments.length"
                bordered
                color="red"
                overlap
                class="my-2"
                :class="{ 'mx-3': index % 2 === 0 }"
              >
                <v-chip label color="transparent">
                  <v-avatar>
                    <v-img
                      :src="
                        recruter.user.photo
                          ? `${apiUrl}/images/user/${recruter.user.photo}`
                          : `/avatar.png`
                      "
                    ></v-img>
                  </v-avatar>
                  <div class="recruter-name ml-1">
                    <div class="width: 100%" style="display: inline-flex">
                      {{ recruterName(recruter) }}:
                      <!-- </div>
                    <div class="whirelab-results-rating-value"> -->
                      <v-progress-linear
                        :value="ratingMember(recruter)"
                        readonly
                        height="16"
                        rounded
                        :color="thumbColor(ratingMember(recruter))"
                        class="mt-0 ml-1"
                        style="width: 100px;"
                      >
                        <span class="black--text"
                          >{{ ratingMember(recruter) }}%</span
                        >
                      </v-progress-linear>
                    </div>
                  </div>
                </v-chip>
                <!-- <pre> {{ ratingMember(recruter) }}</pre> -->
              </span>
              <span
                v-for="(observer, index) in observers"
                :key="observer.user.id"
                :content="observer.comments.length"
                :value="observer.comments.length"
                bordered
                color="red"
                overlap
                class="my-2"
                :class="{ 'mx-3': index % 2 === 0 }"
              >
                <v-chip label color="transparent">
                  <v-avatar>
                    <v-img
                      :src="
                        observer.user.photo
                          ? `${apiUrl}/images/user/${observer.user.photo}`
                          : `/avatar.png`
                      "
                    ></v-img>
                  </v-avatar>
                  <div
                    class="width: 100%"
                    style="width: 100px; display: inline-flex"
                  >
                    {{ recruterName(observer) }}:
                    <!-- </div>
                    <div class="whirelab-results-rating-value"> -->
                    <v-progress-linear
                      :value="ratingMember(observer)"
                      readonly
                      height="16"
                      rounded
                      :color="thumbColor(ratingMember(observer))"
                      class="mt-0 ml-1"
                    >
                      <span class="black--text"
                        >{{ ratingMember(observer) }}%</span
                      >
                    </v-progress-linear>
                  </div>
                </v-chip>
                <!-- <pre>{{ observer }}</pre> -->
              </span>
            </div>
          </div>
        </v-row>
      </div>
    </div>

    <v-dialog v-model="classificationDialog" persistent max-width="700px">
      <v-card class="pa-5">
        <v-container>
          <v-row
            class="d-flex flex-row justify-space-between align-center mb-1"
          >
            <div style="width: 100%">
              <v-text-field
                :label="$t('inserts_points_evaluate')"
                outlined
                dense
                hide-details
                v-model="classification.designation"
              ></v-text-field>
            </div>
          </v-row>
          <v-row>
            <template
              v-for="(evaluationPoint,
              index) in classification.evaluationPoints"
            >
              <div
                class="d-flex align-center mb-2"
                style="width: 95%"
                :key="`${index}gsgs`"
              >
                <div class="mx-2" style="width: 2%">
                  <v-icon small>mdi-arrow-right-bottom</v-icon>
                </div>
                <div class="pr-2" style="width: 76%">
                  <v-text-field
                    :label="$t('what_aspects')"
                    outlined
                    dense
                    hide-details
                    v-model="evaluationPoint.name"
                  ></v-text-field>
                </div>
                <div style="width: 19%">
                  <v-text-field
                    :label="$t('weight')"
                    outlined
                    dense
                    :rules="requiredRules"
                    hide-details
                    v-model.number="evaluationPoint.percentageWeight"
                  ></v-text-field>
                </div>
                <div class="ml-2" style="width: 1%">
                  <v-icon
                    v-if="classification.evaluationPoints.length > 1"
                    @click="removeEvaluationPoint(index)"
                    >mdi-delete</v-icon
                  >
                </div>
              </div>
            </template>
            <div
              class="primary--text ml-10 mb-5"
              style="cursor: pointer"
              @click="addEvaluationPoints()"
            >
              {{ $t("add") }}
            </div>
          </v-row>
          <v-row class="d-flex flex-row justify-end align-center">
            <v-btn
              text
              small
              class="mr-2"
              @click="cancelUpdateClassification()"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn dark small color="primary" @click="updateClassification()">
              {{ $t("save") }}
            </v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <confirm-dialog
      :dialogConfirm="dialogConfirm"
      @yes="deleteClassification"
      @no="dialogConfirm.dialog = false"
    />

    <classification-list-dialog />
    <notes
      :dialog="noteDialog"
      :interviewId="interviewId"
      :selectedCandidate="selectedCandidate"
      :classification="classificationNote"
      :selectedStage="selectedStage"
      :memberInterview="memberInterview"
      :disabled="selectedRecruter.user.id != currentUser.id"
      @close="noteDialog = false"
    />
  </div>
</template>

<script>
import { API_URL } from "@/api";
import {
  ADD_INTERVIEW_CLASSIFICATION_MUTATION,
  UPDATE_RECRUTER_CLASSIFICATION_MUTATION,
  UPDATE_INTERVIEW_CLASSIFICATION_MUTATION,
  REMOVE_INTERVIEW_CLASSIFICATION_MUTATION,
  SET_INTERVIEW_CLASSIFICATION_MUTATION,
} from "../../graphql/Mutation.gql";
import { GET_FAVORITE_RATING_QUERY } from "../../graphql/Query.gql";
import ConfirmDialog from "../ConfirmDialog.vue";
import ClassificationListDialog from "../ClassificationListDialog.vue";
import Notes from "./Notes.vue";
import { mapGetters } from "vuex";
export default {
  name: "Classification",

  components: {
    ConfirmDialog,
    ClassificationListDialog,
    Notes,
  },

  props: {
    interviewId: { type: String },
    interviews: { type: Array },
    selectedCandidate: { type: Object },
    selectedRecruter: { type: Object },
    isCompleted: { type: Boolean },
    isRunning: { type: Boolean },
    isInterviewer: { type: Boolean },
    isObserver: { type: Boolean },
    selectedStage: { type: String },
  },

  data: () => ({
    apiUrl: API_URL,
    dialogConfirm: {
      dialog: false,
      question: "",
      id: null,
    },
    value: 0,
    classification: {
      designation: "",
      points: 0,
      evaluationPoints: [
        {
          name: "",
          percentageWeight: null,
          scoring: null,
        },
      ],
      percentageWeight: null,
      createdBy: null,
    },
    emptyClassification: {
      designation: "",
      points: 0,
      evaluationPoints: [
        {
          name: "",
          percentageWeight: null,
          scoring: null,
        },
      ],
      percentageWeight: null,
      createdBy: null,
    },
    classifications: [],
    classificationDialog: false,
    historic: null,
    valid: true,
    noteDialog: false,
    member: undefined,
    idclass: undefined,
  }),

  apollo: {
    lists: {
      query: GET_FAVORITE_RATING_QUERY,
      fetchPolicy: "no-cache",
    },
  },

  watch: {
    historic(value) {
      if (value) {
        console.log(value.classification);
        this.setClassification(value.classification);
      }
    },
  },

  created() {
    this.member = {
      ...this.selectedRecruter.user,
      isObserver: this.selectedRecruter.isObserver,
    };
  },
  mounted() {
    this.member = {
      ...this.selectedRecruter.user,
      isObserver: this.selectedRecruter.isObserver,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    classificationNote() {
      return this.selectedRecruter.classification.find(
        (cl) => cl.id === this.idclass
      );
    },
    memberInterview() {
      return {
        evaluatorType: this.member.isObserver ? "observer" : "recruter",
        evaluatorID: this.currentUser
          ? this.member.id === this.currentUser.id
            ? this.member.id
            : undefined
          : undefined,
      };
    },
    userId() {
      return this.$root.$data.userId;
    },
    rantingMemberQuestion() {
      return {
        user: this.selectedRecruter.user,
        rating: this.selectedRecruter.classification,
      };
    },
    readOnlyClassification() {
      return this.selectedRecruter.user.id !== this.userId;
    },

    specificRating() {
      const stage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedStage
      );

      return stage;
    },

    requiredRules() {
      return [(v) => !!v || this.$t("form_user_valid")];
    },
    recruters() {
      return this.selectedCandidate.stages.find(
        (st) => st.name === this.selectedStage
      ).recruters;
    },
    observers() {
      return this.selectedCandidate.stages.find(
        (st) => st.name === this.selectedStage
      ).observers;
    },
  },

  methods: {
    recruterName(recruterOrObserver) {
      return recruterOrObserver.user.id === this.userId
        ? this.$t("me")
        : this.recruterSplitName(recruterOrObserver.user.name);
    },
    recruterSplitName(name) {
      return name.split(" ")[0];
    },
    ratingMember(recruter) {
      let total = 0;
      let sum = 0;
      recruter.classification.forEach((cls) => {
        sum += parseFloat(cls.points);
        total += 1;
      });
      if (total > 0) {
        return (sum / total).toFixed(2);
      } else {
        return sum.toFixed(2);
      }
    },
    getSpecificRatingInQuestion(id) {
      let rating = this.rantingMemberQuestion.rating.find((rt) => rt.id === id);
      return parseFloat(rating.points).toFixed(2);
    },
    openList() {
      // eslint-disable-next-line no-undef
      Fire.$emit(
        "openCalssificationListDialog",
        this.selectedRecruter.classification
      );
    },

    thumbColor(points) {
      if (points < 40) return "red";
      if (points < 50) return "orange";
      if (points < 75) return "indigo";
      if (points < 100) return "teal";
      return "green";
    },

    addEvaluationPoints() {
      this.classification.evaluationPoints.push({
        name: "",
        percentageWeight: null,
        scoring: null,
      });
    },

    removeEvaluationPoint(index) {
      this.classification.evaluationPoints.splice(index, 1);
    },

    async updateRecruterClassification(classification, index) {
      try {
        const candidateIds = this.selectedCandidate.interviewee
          ? this.selectedCandidate.interviewee.id
          : this.selectedCandidate.email;

        const currentStage = this.getCurrentStage();

        await this.$apollo.mutate({
          mutation: UPDATE_RECRUTER_CLASSIFICATION_MUTATION,
          variables: {
            interviewRecruterClassificationInput: {
              interviewId: this.interviewId,
              candidateIdOrEmail: candidateIds,
              stage: currentStage,
              recruterId: this.selectedRecruter.user.id,
              classificationId: classification.id,
              points:
                classification.evaluationPoints[index].scoring *
                (classification.evaluationPoints[index].percentageWeight / 100),
              scoring: classification.evaluationPoints[index].scoring,
              evaluationPointIndex: index,
              isObserver: this.selectedRecruter.isObserver,
            },
          },
        });
      } catch (error) {
        console.log(error);
      }
    },

    async addClassification() {
      if (this.$refs.form.validate()) {
        try {
          const candidateIds = this.getCandidateIdsFromInterviews();
          const currentStage = this.getCurrentStage();
          this.classification.createdBy = this.userId;

          await this.$apollo.mutate({
            mutation: ADD_INTERVIEW_CLASSIFICATION_MUTATION,
            variables: {
              interviewClassificationInput: {
                interviewId: this.interviewId,
                candidates: candidateIds,
                stage: currentStage,
                classification: this.classification,
              },
            },
          });
          this.classification = this.emptyClassification;
          this.classification.designation = "";
          this.classification.percentageWeight = null;
          this.classification.evaluationPoints = [];
        } catch (error) {
          console.log(error);
        } finally {
          this.classification = this.emptyClassification;
          this.classification.designation = "";
          this.classification.percentageWeight = null;
          this.classification.evaluationPoints = [];
        }
      }
    },

    async setClassification(value) {
      const candidateIds = this.getCandidateIdsFromInterviews();
      const currentStage = this.getCurrentStage();
      let classifications = value.filter(
        (object1) =>
          this.selectedRecruter.classification
            .map((object2) => object2.designation)
            .indexOf(object1.designation) === -1
      );

      if (classifications.length > 0) {
        try {
          classifications = classifications.map((mapValue) => ({
            ...mapValue,
            createdBy: this.userId,
          }));

          await this.$apollo.mutate({
            mutation: SET_INTERVIEW_CLASSIFICATION_MUTATION,
            variables: {
              interviewClassificationInput: {
                interviewId: this.interviewId,
                candidates: candidateIds,
                stage: currentStage,
                classifications,
              },
            },
          });
          this.classification = this.emptyClassification;
          this.classification.designation = "";
          this.classification.percentageWeight = null;
        } catch (error) {
          console.log(error);
        }
      }
    },

    getCandidateIdsFromInterviews() {
      const candidates = this.interviews.map((mapInterview) => {
        return mapInterview.interviewee
          ? mapInterview.interviewee.id
          : mapInterview.email;
      });

      return candidates;
    },

    getCurrentStage() {
      const FIRST_ELEMENT = 0;
      const currentStage = this.interviews[FIRST_ELEMENT].currentStage;

      return currentStage;
    },

    setClassificationToEdit(classification) {
      this.classification = classification;
      this.classificationDialog = true;
    },

    setClassificationToDelete(classification) {
      this.dialogConfirm.id = classification.id;
      this.dialogConfirm.question = this.$t(
        "interview_classification_remove_question"
      );
      this.dialogConfirm.dialog = true;
    },

    async updateClassification() {
      try {
        const candidateIds = this.getCandidateIdsFromInterviews();
        const currentStage = this.getCurrentStage();

        //this.classification.evaluationPoints[0].scoring = 0;
        delete this.classification.notes;
        //console.log(this.classification);

        await this.$apollo.mutate({
          mutation: UPDATE_INTERVIEW_CLASSIFICATION_MUTATION,
          variables: {
            interviewClassificationInput: {
              interviewId: this.interviewId,
              candidates: candidateIds,
              stage: currentStage,
              classificationId: this.classification.id,
              classification: this.classification,
            },
          },
        });
        this.classification = this.emptyClassification;
        this.classificationDialog = false;
      } catch (error) {
        console.log(error);
      }
    },

    cancelUpdateClassification() {
      this.classification = this.emptyClassification;
      this.classificationDialog = false;
    },

    async deleteClassification(classificationId) {
      try {
        const candidateIds = this.getCandidateIdsFromInterviews();
        const currentStage = this.getCurrentStage();

        await this.$apollo.mutate({
          mutation: REMOVE_INTERVIEW_CLASSIFICATION_MUTATION,
          variables: {
            interviewClassificationInput: {
              interviewId: this.interviewId,
              candidates: candidateIds,
              stage: currentStage,
              classificationId,
            },
          },
        });
        this.dialogConfirm.dialog = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.whirelab-results-rating-value {
  width: 100%;
}
</style>
