<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="350"
  >
    <v-card>
      <v-card-title class="justify-end primary--text caption pa-1">
        <v-btn
          x-small
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="text-center justify-center pt-1">
        <div class="mb-2">
          <v-icon color="warning">mdi-alert-circle-outline</v-icon>
        </div>
        <span class="pt-1">{{ message }}</span>

        <v-row justify="center">
          <v-col>
            <v-btn
              color="primary"
              @click="$emit('close')"
            >OK</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'WarningDialog',
  props: {
    dialog: Boolean,
    message: String
  },
  data: () => {
    return {
      // dialog: false,
      // message: ''
    }
  },
  created () {
    // eslint-disable-next-line no-undef
    // Fire.$on("warningDialog", (message) => {
    //   this.message = message;
    //   this.dialog = true;
    // });
  }
}
</script>