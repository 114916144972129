<template>
  <v-dialog v-model="dialog" scrollable max-width="300px">
    <v-card>
      <v-card-title>
        {{ $t("saveto") }}...
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 300px">
        <template v-for="list in lists">
          <div
            class="d-flex flex-row justify-space-between align-center"
            :key="list.id"
          >
            <div>
              <v-checkbox
                v-model="selected"
                :label="list.name"
                :value="list.id"
                hide-details
                @change="addRatingList(list.id)"
              ></v-checkbox>
            </div>
            <v-spacer></v-spacer>
            <div class="mt-5">
              <v-icon color="error" @click="openConfirmeDialog(list.id)"
                >mdi-close-circle</v-icon
              >
            </div>
          </div>
        </template>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn v-show="!create" block text @click="create = true">
          <v-icon>mdi-plus</v-icon>
          <span class="text-lowercase">{{ $t("createlist") }}</span>
        </v-btn>
        <v-row v-show="create">
          <v-col cols="12" md="12">
            <v-text-field
              v-model="name"
              :label="$t('form_candidate_name')"
              clearable
              :placeholder="$t('insertNamelist')"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12" class="text-end">
            <v-btn text color="primary" @click="createList()">
              {{ $t("create") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>

      <confirm-dialog
        :dialog="confirm"
        :id="removeId"
        :question="$t('removeItemQ')"
        @yes="deleteList"
        @no="confirm = false"
      />

      <error-dialog
        :dialog="showError"
        :message="error"
        @close="showError = false"
      />
    </v-card>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import {
  ADD_FAVORITE_RATING_MUTATION,
  DELETE_FAVORE_RATING_MUTATION,
  ADD_RATING_TO_FAVORITE_MUTATION,
  REMOVE_RATING_FROM_FAVORITE_MUTATION,
} from "../graphql/Mutation.gql";
import { GET_FAVORITE_RATING_QUERY } from "../graphql/Query.gql";
import dialogMixins from "@/mixins/dialog";
import { formatError } from "@/utils";
import ConfirmDialog from "./../../../components/ConfirmDialog.vue";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
export default {
  name: "ClassificationListDialog",
  components: { ConfirmDialog, ErrorDialog },
  mixins: [dialogMixins],
  data: () => ({
    create: false,
    lastList: null,
    lists: [],
    name: "",
    selected: [],
    snackbar: false,
    text: "Adicionado a ",
    timeout: 2000,
    classification: [],
  }),
  apollo: {
    lists: {
      query: GET_FAVORITE_RATING_QUERY,
      fetchPolicy: "no-cache",
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.setInit();
      }
    },
  },

  created() {
    // eslint-disable-next-line no-undef
    Fire.$on("openCalssificationListDialog", (classifications) => {
      this.classification = classifications.map((c) => ({
        designation: c.designation,
        percentageWeight: c.percentageWeight,
        evaluationPoints: c.evaluationPoints,
        points: 0,
      }));
      this.dialog = true;
    });
  },

  methods: {
    async addRatingList(id) {
      console.log(id);
      const selectedIndex = this.selected.findIndex((l) => l == id);
      if (selectedIndex > -1) {
        try {
          const { data } = await this.$apollo.mutate({
            mutation: ADD_RATING_TO_FAVORITE_MUTATION,
            variables: { id, classifications: this.classification },
          });
          const index = this.lists.findIndex((l) => l.id == id);
          // this.lists.splice(index, 1, data.addCandidateList)
          this.lists[index].classification =
            data.addRatingToFavorite.classification;
          this.lastList = id;
          this.text = `${this.$t("candidate_add_fav")} ${
            data.addRatingToFavorite.name
          }`;
          this.snackbar = true;
        } catch (error) {
          this.error = formatError(error.message);
          this.showError = true;
        }
      } else {
        await this.removeRating(id);
      }
    },
    async createList() {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: ADD_FAVORITE_RATING_MUTATION,
          variables: {
            favoriteRatingInput: {
              name: this.name,
              classification: this.classification,
            },
          },
        });
        this.selected.push(data.addFavoriteRating.id);
        this.lists.push(data.addFavoriteRating);
        this.name = "";
        this.create = false;
        this.text = `${this.$t("candidate_add_fav")} ${
          data.addFavoriteRating.name
        }`;
        this.lastList = data.addFavoriteRating.id;
        this.snackbar = true;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      }
    },
    async deleteList(id) {
      console.log({ id });
      try {
        this.confirm = false;
        await this.$apollo.mutate({
          mutation: DELETE_FAVORE_RATING_MUTATION,
          variables: { id },
        });
        const selectedInex = this.selected.findIndex((s) => s == id);
        this.selected.splice(selectedInex, 1);
        const index = this.lists.findIndex((l) => l.id == id);
        this.lists.splice(index, 1);
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      }
    },
    openConfirmeDialog(id) {
      this.removeId = id;
      this.confirm = true;
    },
    async removeRating(id) {
      console.log(id);
      try {
        const { data } = await this.$apollo.mutate({
          mutation: REMOVE_RATING_FROM_FAVORITE_MUTATION,
          variables: { id, classifications: this.classification },
        });
        const index = this.lists.findIndex((l) => l.id == id);
        this.lists.splice(index, 1, data.removeRatingFromFavorite);
        this.lastList = id;
        this.text = `${this.$t("candidate_remove_fav")} ${
          this.lists[index].name
        }`;
        this.snackbar = true;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      }
    },
    setInit() {
      this.selected = [];
      this.lists.forEach((l) => {
        const total = l.classification.filter(
          (object1) =>
            this.classification
              .map((object2) => object2.designation)
              .indexOf(object1.designation) >= 0
        ).length;

        if (
          l.classification.length >= this.classification.length &&
          this.classification.length === total
        )
          this.selected.push(l.id);
      });
    },
  },
  beforeDestroy() {
    this.$emit("close");
  },
};
</script>
