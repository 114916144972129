<template>
  <v-dialog v-model="dialog" persistent max-width="750">
    <v-card class="text-center">
      <v-card-title class="text-center">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon small v-on="on">
              mdi-information-variant-circle-outline
            </v-icon>
          </template>
          <div
            style="width: 360px;"
            v-html="$t('the_stages_recruitment_process')"
          ></div> </v-tooltip
        >{{ $t("add_new_stage") }}
      </v-card-title>
      <v-container class="fill-heigt pt-0" fluid>
        <v-row class="justify-center">
          <v-col cols="12" md="12">
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="stage.name"
                    :label="language.add_name_stage"
                    outlined
                    dense
                  ></v-text-field>
                  <v-textarea
                    v-model="stage.analyze"
                    :label="language.what_aspects_intend_analyze"
                    auto-grow
                    type="text"
                    dense
                    outlined
                    rows="1"
                    :rules="requiredRules"
                  ></v-textarea>
                </v-col>
                <!-- <v-col cols="12" md="1" class="text-center mt-1">
                  <v-btn
                    x-small
                    outlined
                    fab
                    color="secondary"
                    @click="index === 0 ? addStage() : removeStage(index)"
                  >
                    <v-icon v-if="index === 0" small>mdi-plus</v-icon>
                    <v-icon v-else small>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-col> -->
              </v-row>
            </v-col>
          </v-col>
          <v-col cols="12" md="12" class="text-center">
            <v-btn class="mr-2" @click="$emit('close')">
              {{ $t("close") }}
            </v-btn>
            <v-btn color="primary" @click="addStage()">
              {{ $t("add_new_stage") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { ADD_STAGE_MUTATION } from "../graphql/Mutation.gql";
export default {
  name: "StageDialog",
  props: {
    dialog: Boolean,
    language: Object,
    vacancyId: String,
  },
  data: () => ({
    stage: {
      name: "",
      analyze: "",
    },
  }),
  computed: {
    requiredRules() {
      return [(v) => !!v || "Campo obrigatório"];
    },
  },
  methods: {
    continueToPub() {
      this.$emit("continue", this.stages);
    },
    async addStage() {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: ADD_STAGE_MUTATION,
          variables: {
            id: this.vacancyId,
            stageInput: {
              name: this.stage.name,
              analyze: this.stage.analyze,
            },
          },
        });

        this.stage.name = "";
        this.stage.analyze = "";

        this.$emit(
          "done",
          data.addStage.stages[data.addStage.stages.length - 1]
        );
      } catch (error) {
        console.log(error);
      }
    },
    removeStage(index) {
      this.stages.splice(index, 1);
    },
  },
};
</script>
